.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.passforget_user {
  position: absolute;
  top: 0;
  right: 11px;
  transform: translateY(30%);
}
.disable_ms {
  background-color: #e9ecef !important;
  opacity: 1 !important;
}
.cross-field {
  background: url("../public/assets/img/cross-field.png");
  background-size: cover;
}
/* new css */
.optionListContainer {
  z-index: 3 !important;
}
.react-daterange-picker__wrapper {
  border: 1px solid #ccc !important;
  border-radius: 4px;
  padding: 5px;
}
tfoot tr td.css-1f7yhpv-MuiTableCell-root {
  border-right: 2px solid rgba(224, 224, 224, 1) !important;
}
.react-datetimerange-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  border: 1px solid #ced4da !important;
  padding: 5px;
  border-radius: 4px;
}
.react-datetimerange-picker {
  width: 100%;
}
.rc-time-picker-clear-icon:after {
  content: "X" !important;
  font-size: 15px !important;
  font-weight: 900 !important;
  color: red !important;
}
.rc-time-picker-clear {
  right: 15px !important;
  top: 12px !important;
}
.content {
  width: 100%;
}
/* css for suggestions */
.css-1w86f15 {
  justify-content: center !important;
}
.css-hsi95o-MuiTableRow-root td {
  text-align: center;
}
.css-hsi95o-MuiTableRow-root td div {
  justify-content: center;
}

.disabled-div {
  pointer-events: none;
  opacity: 0.5;
  z-index: 9999;
}

/* checkpoint modal  */
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1021 !important;
}
.custom-modal {
  background: #fff;
  width: 80%;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  position: absolute;
  z-index: 1021;
}
.custom-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #222e3c;
  color: #fff;
}
.custom-modal-title {
  margin: 0;
  font-size: 18px;
}
.custom-modal-close {
  background: none;
  border: none;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}
.custom-modal-body {
  padding: 16px;
  max-height: 300px;
  overflow-y: auto;
}
.custom-modal-footer {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  background: #f1f1f1;
}
.custom-modal-button {
  background: #222e3c;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}
.custom-modal-button:hover {
  background: #495058;
}
.chckpnt-usr-lst {
  position: absolute;
  top: 0;
  right: 0px;
  z-index: 999;
}
.user-speed-control {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 401;
  background: #fff;
  padding: 5px;
}
.play-pause-controls {
  position: absolute;
  top: 0px;
  right: 83px;
  z-index: 1000;
}
.leaflet-control-attribution {
  display: none;
}
.css-1t5kuvk {
  min-width: 181px !important;
}
.optionListContainer {
  z-index: 999 !important;
}
.multiSelectContainer::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border: 2px solid #616468;
  border-width: 0px 0px 2px 2px;
  transform: rotate(-45deg);
  right: 20px;
  top: 15px;
}
.date-picker-container {
  position: relative;
  width: fit-content;
}

.placeholder {
  position: absolute;
  top: 10px;
  left: 12px;
  color: #aaa;
  font-size: 14px;
  pointer-events: none;
  z-index: 10;
}
.btn-sg-height {
  height: 40px !important;
}
.react-daterange-picker__wrapper {
  flex-grow: inherit !important;
  padding: 8.5px 5px !important;
}
.react-daterange-picker__inputGroup {
  flex-grow: inherit !important;
}
.react-daterange-picker {
  display: block !important;
}
.react-daterange-picker__button {
  position: absolute !important;
  right: 5px !important;
}
.leaflet-marker-icon .leaflet-zoom-animated .leaflet-interactive {
  margin-left: 5px !important;
}
